import styled from "styled-components"

export const ContactWrapper = styled.div`
  text-align: center;
  background-color: white;
  padding: 3rem 2rem 6rem;

  .heading-section {
    margin-bottom: 3rem;
  }
  .inner-wrapper {
    width: 500px;
    max-width: 100%;
    margin: 0 auto;

    .success-message {
      color: green;
      border: 2px solid green;
      border-radius: 3px;
      padding: 0.4rem;
      font-size: 1.2rem;

      span {
        display: inline-block;
        font-size: 1.3rem;
        margin-left: 0.6rem;
      }
    }
    form {
      display: flex;
      flex-direction: column;

      .input-wrapper {
        display: flex;
        flex-direction: column;

        .error-message p {
          margin: 0 0 1rem 0.2rem;
          text-align: left;
          color: crimson;
        }
        label.screen-reader {
          position: absolute;
          left: -10000px;
          top: auto;
          width: 1px;
          height: 1px;
          overflow: hidden;
        }
        input,
        select,
        textarea {
          font-size: 16px;
          padding: 0.6rem 1rem;
          margin-bottom: 0.4rem;
          outline: none;
          border: 1px solid gray;
          border-radius: 3px;
          transition: 0.2s all ease-in-out;

          :focus {
            border: 1px solid crimson;

            ::placeholder {
              opacity: 0;
            }
          }
          ::placeholder {
            color: gray;
            font-size: 16px;
          }
        }
        select[value="select"] {
          color: #9e9e9e;
        }
        select {
          option:not(:first-of-type) {
            color: black;
          }
          option[value="select"][disabled] {
            color: #9e9e9e;
          }
        }
      }
      button {
        margin-top: 0.4rem;
        padding: 0.8rem 1rem;
        cursor: pointer;
        background-color: crimson;
        color: white;
        outline: none;
        transition: 0.2s all ease-in-out;
        border: 1px solid white;
        border-radius: 3px;

        :not(:disabled):hover {
          opacity: 0.7;
        }
      }
    }
  }

  @media (max-width: 400px) {
    padding: 3rem 0.7rem 6rem;
  }
`
