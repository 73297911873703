import React, { Fragment, Component } from "react"
import Seo from "../components/Seo"
import Layout from "../components/Layout"
import { ContactWrapper } from "../components/Style/contact"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class FeedbackPage extends Component {
  constructor() {
    super()
    this.state = {
      name: "",
      email: "",
      message: "",
      thankyou: false,
      error: {
        nameError: false,
        emailError: false,
        selectError: false,
        messageError: false,
      },
    }
  }

  onChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const { name, email, message } = this.state
    let hasError = false

    if (name === "" || email === "" || message === "") {
      hasError = true
      if (name === "") {
        this.setState({ error: { nameError: true } })
      } else if (email === "") {
        this.setState({ error: { emailError: true } })
      } else if (message === "") {
        this.setState({ error: { messageError: true } })
      }
    }

    if (!hasError) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "feedback", ...this.state }),
      })
        .then(() => this.setState({ thankyou: true }))
        .catch(error => {
          console.log("ooops something went wrong..")
        })
    }
  }

  render() {
    const { name, email, message, thankyou, error } = this.state
    const isInvalid = name === "" || email === "" || message === ""

    return (
      <Fragment>
        <Seo
          title={`Feedback`}
          description={`We'd love to hear from our players. Please use the feedback form to contact us.`}
        />

        <Layout>
          <ContactWrapper>
            <div className="inner-wrapper">
              <div className="heading-section">
                <h1>Feedback Form</h1>
                <p>We'd love to hear from our players!</p>
                <p>
                  Let us know about your experience and how we can improve our
                  platform! :)
                </p>
              </div>
              {thankyou ? (
                <p className="success-message">
                  Your feedback has been sent!{" "}
                  <span role="img" aria-label="rocket emoji">
                    🚀
                  </span>
                </p>
              ) : (
                <form
                  onSubmit={event => this.handleSubmit(event)}
                  name="feedback"
                  method="post"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <div className="form-inner-wrapper">
                    <div className="input-wrapper">
                      <label className="screen-reader" htmlFor="name">
                        Name:
                      </label>
                      <input
                        id="name"
                        type="text"
                        name="name"
                        placeholder="Your name"
                        value={name}
                        onChange={this.onChange}
                      />
                      {error["nameError"] && (
                        <div className="error-message">
                          <p>Name field is required</p>
                        </div>
                      )}
                    </div>
                    <div className="input-wrapper">
                      <label className="screen-reader" htmlFor="email">
                        Email:
                      </label>
                      <input
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Your email"
                        value={email}
                        onChange={this.onChange}
                      />
                      {error["emailError"] && (
                        <div className="error-message">
                          <p>Email field is required</p>
                        </div>
                      )}
                    </div>

                    <div className="input-wrapper">
                      <label className="screen-reader" htmlFor="message">
                        Let us know what you think!
                      </label>
                      <textarea
                        id="message"
                        name="message"
                        cols="30"
                        rows="10"
                        placeholder="Let us know what you think!"
                        value={message}
                        onChange={this.onChange}
                      ></textarea>
                      {error["messageError"] && (
                        <div className="error-message">
                          <p>Message field is required</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <input type="hidden" name="form-name" value="feedback" />
                  <button type="submit" disabled={isInvalid}>
                    Submit!
                  </button>
                </form>
              )}
            </div>
          </ContactWrapper>
        </Layout>
      </Fragment>
    )
  }
}

export default FeedbackPage
